import { useState, useEffect} from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import {Routes, Route, useNavigate } from 'react-router-dom';
import AppBar from './components/AppBar';
import ReportType from './routes/ReportType';
import ObservationType from './routes/ObservationType';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FuelSpillage from './routes/FuelSpillage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Riddor from './routes/Riddor';
import Site from './routes/Site';
import Involved from './routes/Involved';
import Witnessed from './routes/Witnessed';
import InjuryDetails from './routes/InjuryDetails';
import EnvDetails from './routes/EnvDetails';
import Notes from './routes/Notes';
import AccidentSummary from './routes/AccidentSummary';
import AccidentUserDetails from './routes/AccidentUserDetails';
import Public from './routes/Public';
import Success from './routes/Success';
import SuccessEmail from './routes/SuccessEmail';
import Error from './routes/ErrorPage';
import LoginPage from './routes/Login';
import SendReportReview from './routes/SendReportReview';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light:'#36b76f',
        main: '#04a64b',
        dark:'#027434'
      },
      secondary:{
        light:'#ed7148',
        main:'#E94E1B',
        dark:'#a33612',
      },
      error:{
        light:'#ed7148',
        main:'#E94E1B',
        dark:'#a33612',
      }
    },
  });
  const navigate = useNavigate();
  let location = window.location.href;
  useEffect(()=>{
      if(location.includes("public")){
        navigate('/public');
      }
      else{
        navigate('/');
      }
  },[]);

  const current_url = window.location.pathname;

  // this function will work only when you do reload. 
    window.onbeforeunload = (event) => {
      event.preventDefault();
      localStorage.setItem("page",current_url) // Store the page URL 
    };
  
  // After first redirection and due to bounce effect will come back to current page.
    useEffect(() => {
    
    // if your localstorage have the key "page and the value is the current page...
      if(localStorage.getItem("page") === current_url){
        
        localStorage.removeItem("page"); // also you have to remove the item from localstorage. 
        if(location.includes("public")){
          navigate('/public');
        }
        else{
          navigate('/');
        }
      }
    },[])



  
  const [dateError, setDateError] = useState(false);
  const [reportType, setReportType] = useState("");
  const [observationType, setObservationType] = useState("");
  const [fuelSpillValue, setFuelSpillValue] = useState(null);
  const [measureControlValue, setMeasureControlValue] = useState(null);

  const [timeDate, setTimeDate] = useState(null);
  const [site, setSite] = useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [showTextfield, setShowTextField] = useState(false);
  const [supplier, setSupplier] = useState(null);
  const [profession, setProfession] = useState(null);
  const [reportCategory, setReportCategory] = useState(null);
  const [workingArea, setWorkingArea] = useState("");
  const [supplierTextField, setSupplierTextField] = useState('');
  const [memberOfThePublic, setMemberOfThePublic] = useState(false);

  const [isSiteTeam, setIsSiteTeam] = useState(null);
  const [generateLink, setGenerateLink] = useState(null);

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [postcode, setPostcode] = useState("");
  const [consentToShare, setConsentToShare] = useState(false);
  const [accidentActionTaken, setAccidentActionTaken] = useState("");

  const [notOnSite, setNotOnSite] = useState(false);
  const [showNameTextField, setShowNametextField] = useState(false);
  const[namesOfPplInvolved, setNamesOfPplInvolved] = useState('');
  const[injuredIndividual, setInjuredIndividual] = useState(null);
 
  const [age, setAge] = useState("");
  const[body_part_injured, setBody_part_injured] = useState("");
  const[severity_of_injury, setSeverity_of_injury] = useState("");

  const[weather, setWeather] = useState('');
  const[lighting, setLighting] = useState('');
  const[underfoot_condition, setUnderfoot_condition] = useState('');

  const[witnessed, setWitnessed] = useState('');
  const [imgFile, setImgFile] = useState();
  const[notes, setNotes] = useState('');

  const[accidentSummary, setAccidentSummary] = useState('');
  const[nameOfCompleting, setNameOfCompleting] = useState('');
  
  const[riddor, setRiddor] = useState(null);
  const[sendSupplierEmail, setSendSupplierEmail] = useState(null);
  const[reportSender, setReportSender] = useState('');
  const [file, setFile] = useState([]);

  const [reportReviewLink, setReportReviewLink] = useState('');
  const [adminID, setAdminID] = useState('');
  const [adminFirstName, setAdminFirstName] = useState('');
  const [adminLastName, setAdminLastName] = useState('');
  const [reportID, setReportID] = useState('');
  return (

      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>

      <AppBar />
      <Routes>
        <Route path="/" element={<ReportType setReportType={setReportType} setReportSender={setReportSender}/>} />
        <Route path="/riddor" element={<Riddor reportCategory={reportCategory} setReportCategory={setReportCategory} 
        sendSupplierEmail={sendSupplierEmail} setSendSupplierEmail={setSendSupplierEmail} memberOfThePublic={memberOfThePublic} 
        setMemberOfThePublic={setMemberOfThePublic} reportType={reportType} observationType={observationType} fuelSpillValue={fuelSpillValue} 
        measureControlValue={measureControlValue} timeDate ={timeDate} siteSelection ={site} contactNumber ={contactNumber} supplierSelection={supplier} 
        professionSelection={profession}  workingArea={workingArea} supplierTextField={supplierTextField} witnessed={witnessed} checked={checked}
        age={age} body_part_injured={body_part_injured} severity_of_injury={severity_of_injury} imgFile={imgFile} weather={weather} lighting={lighting} underfoot_condition={underfoot_condition} notes={notes} accidentSummary={accidentSummary}
         nameOfCompleting={nameOfCompleting} riddor={riddor} setRiddor={setRiddor} file={file} setFile={setFile} setReportType={setReportType} 
         setObservationType={setObservationType} setFuelSpillValue={setFuelSpillValue} setMeasureControlValue={setMeasureControlValue} 
         setTimeDate ={setTimeDate} setSiteSelection ={setSite} setContactNumber ={setContactNumber} setSupplierSelection={setSupplier} 
         setProfessionSelection={setProfession}  setWorkingArea={setWorkingArea} setSupplierTextField={setSupplierTextField} setWitnessed={setWitnessed}
          setChecked={setChecked} setAge={setAge} setBody_part_injured={setBody_part_injured} setSeverity_of_injury={setSeverity_of_injury} setImgFile={setImgFile} setWeather={setWeather} setLighting={setLighting} setUnderfoot_condition={setUnderfoot_condition} setNotes={setNotes} 
          setAccidentSummary={setAccidentSummary} setNameOfCompleting={setNameOfCompleting} namesOfPplInvolved={namesOfPplInvolved} setNamesOfPplInvolved={setNamesOfPplInvolved} setShowTextField={setShowTextField} isSiteTeam={isSiteTeam} setIsSiteTeam={setIsSiteTeam} 
          address1={address1} setAddress1={setAddress1} address2={address2} setAddress2={setAddress2} city={city} setCity={setCity} county={county} 
          setCounty={setCounty} postcode={postcode} setPostcode={setPostcode} consentToShare={consentToShare} setConsentToShare={setConsentToShare} injuredIndividual={injuredIndividual} setInjuredIndividual={setInjuredIndividual} notOnSite={notOnSite} setNotOnSite={setNotOnSite} 
          accidentActionTaken={accidentActionTaken} setAccidentActionTaken={setAccidentActionTaken} setShowNametextField={setShowNametextField} generateLink={generateLink} setGenerateLink={setGenerateLink}
           reportReviewLink={reportReviewLink} setReportReviewLink={setReportReviewLink} setReportID={setReportID} adminID={adminID} setAdminID={setAdminID} adminFirstName={adminFirstName} setAdminFirstName={setAdminFirstName} adminLastName={adminLastName} setAdminLastName={setAdminLastName}/>}/>
        <Route path="/observation_type" element={<ObservationType setObservationType={setObservationType}/>}/>
        <Route path="/fuel_spillage" element={<FuelSpillage fuelSpillValue ={fuelSpillValue} setFuelSpillValue={setFuelSpillValue}  measureControlValue={measureControlValue} setMeasureControlValue={setMeasureControlValue}/>}/>
        <Route path="/site" element={<Site reportCategory={reportCategory} setReportCategory={setReportCategory} dateError={dateError} setDateError={setDateError} timeDate ={timeDate} setTimeDate={setTimeDate} siteSelection ={site} setSite={setSite} contactNumber ={contactNumber} setContactNumber={setContactNumber} setAdminID={setAdminID}/>} />
        <Route path="/who_involved" element={<Involved reportType={reportType}  supplierSelection={supplier} setSupplier={setSupplier} professionSelection={profession} setProfession={setProfession} nameOfCompleting={nameOfCompleting} setNameOfCompleting={setNameOfCompleting} isSiteTeam={isSiteTeam} setIsSiteTeam={setIsSiteTeam} workingArea={workingArea} setWorkingArea={setWorkingArea} checked={checked} setChecked={setChecked} showTextfield={showTextfield} setShowTextField={setShowTextField} supplierTextField={supplierTextField} setSupplierTextField={setSupplierTextField} adminFirstName={adminFirstName} adminLastName={adminLastName} />}/>
        <Route path="/who_witnessed" element={<Witnessed reportType={reportType} witnessed ={witnessed} setWitnessed={setWitnessed} imgFile={imgFile} setImgFile={setImgFile} file={file} setFile={setFile}/>} />
        <Route path="/injury_details" element={<InjuryDetails age ={age} setAge={setAge} body_part_injured={body_part_injured} setBody_part_injured={setBody_part_injured} severity_of_injury={severity_of_injury} setSeverity_of_injury={setSeverity_of_injury}  />} />
        <Route path="/environment_details" element={<EnvDetails weather={weather} setWeather={setWeather} lighting={lighting} setLighting={setLighting} underfoot_condition={underfoot_condition} setUnderfoot_condition={setUnderfoot_condition}  />} />
        <Route path="/notes" element={<Notes notes={notes} setNotes={setNotes}/>}/>
        <Route path="/accident_summary" element={<AccidentSummary accidentSummary={accidentSummary} setAccidentSummary={setAccidentSummary} accidentActionTaken={accidentActionTaken} setAccidentActionTaken={setAccidentActionTaken} />}/>
        <Route path="/accident_user_details" element={<AccidentUserDetails namesOfPplInvolved={namesOfPplInvolved} setNamesOfPplInvolved={setNamesOfPplInvolved} address1={address1} setAddress1={setAddress1} address2={address2} setAddress2={setAddress2} city={city} setCity={setCity} county={county} setCounty={setCounty} postcode={postcode} setPostcode={setPostcode} consentToShare={consentToShare} setConsentToShare={setConsentToShare} siteSelection ={site} adminID={adminID} notOnSite={notOnSite} setNotOnSite={setNotOnSite} showNameTextField={showNameTextField} setShowNametextField={setShowNametextField} injuredIndividual={injuredIndividual} setInjuredIndividual={setInjuredIndividual}/>}/>
        <Route path="/public" element={<Public setReportSender={setReportSender} timeDate ={timeDate} setTimeDate={setTimeDate} siteSelection ={site} setSite={setSite} notes={notes} setNotes={setNotes} memberOfThePublic={memberOfThePublic} setMemberOfThePublic={setMemberOfThePublic} imgFile={imgFile} setImgFile={setImgFile} file={file} setFile={setFile} dateError={dateError} setDateError={setDateError} />}/>
        <Route path="/success" element={<Success reportSender={reportSender} reportReviewLink={reportReviewLink} setReportReviewLink={setReportReviewLink} setSite={setSite}/>} />
        <Route path="/login" element={<LoginPage setAdminID={setAdminID} reportType={reportType} setAdminFirstName={setAdminFirstName} setAdminLastName={setAdminLastName}/>} />
        <Route path="/send_report_review" element={<SendReportReview reportReviewLink={reportReviewLink} siteSelection ={site} adminID={adminID} reportID={reportID} setReportReviewLink={setReportReviewLink} setSite={setSite} setAdminID={setAdminID} setReportID={setReportID}/>} />
        <Route path="/success_email" element={<SuccessEmail reportSender={reportSender} reportReviewLink={reportReviewLink} setReportReviewLink={setReportReviewLink} setSite={setSite}/>} />
        <Route path="*" element={<Error/>}/>
      </Routes>
      </ThemeProvider>

        </LocalizationProvider>
      </StyledEngineProvider>

  );
}

export default App;
