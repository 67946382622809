import { useState, useLayoutEffect } from 'react';
import styles from '../styles/body.module.scss'
import btnStyles from '../styles/buttons.module.scss'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import{BottomNavigation, BottomNavigationAction} from '@mui/material'
import {CopyField} from '@eisberg-labs/mui-copy-field';

export default function Success({reportSender, reportReviewLink, setReportReviewLink , setSite}) {
  const navigate = useNavigate();


  const [displayGenerateSection, setDisplayGenerateSection] = useState(false);


  useLayoutEffect(() => {
    if(reportReviewLink !== null){
      if(reportReviewLink !== ''){
        setDisplayGenerateSection(true);
      }
    }
  }, [reportReviewLink])


  window.addEventListener('popstate', function(event) {
    setReportReviewLink('');
    setSite(null);
    if(reportSender === 'operative'){
      navigate('/');
    }
    else if(reportSender === 'memberOfPublic'){
      navigate('/public');
    }
  });
  


 
  const homePage = () => {
    setReportReviewLink('');
    setSite(null);
    if(reportSender === 'operative'){
      navigate('/');
    }
    else if(reportSender === 'memberOfPublic'){
      navigate('/public');
    }
    
  }

  const login = () =>{
    navigate('/login')
  }


  return (

    <>
      <Box className={styles.successHeader} >
        <h1>Thank you for submitting your report</h1>
        </Box>
        <Box className={styles.header} >
        <div className={styles.successIcon}> 
        {<CheckCircleOutlineIcon fontSize="inherit"/>}
        </div>
        <h4>Your report has now been received</h4>
        </Box>
      {displayGenerateSection ?
        <>
        <Box className={styles.header} >
        <FormControl fullWidth margin="normal" className={styles.field}  >
        <FormHelperText id="photoText">A link to the report is shown below for you to copy. There is also a button, 'Generate Email', that can be used to send this link directly to individuals joined to the site.</FormHelperText>
        </FormControl>
        </Box>
        
        
          <Box  className={styles.successHeader}>
                <CopyField className={styles.field}
                label="Link to review this report and action"
                value={reportReviewLink}
                copyTooltip={"Copy link"}
              />
          </Box>
          <Box className={btnStyles.singleButtonContainer}>
          <Button startIcon={<EmailIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
            onClick={() => login()} >Generate Email</Button>
        </Box>
        </>
        :
        <></>
      }




        <Box className={btnStyles.singleButtonContainer}>
        <Button startIcon={<ContentPasteIcon />} className={btnStyles.nextBtn} size="large" variant="contained"
          onClick={() => homePage()} >Make another report</Button>
      </Box>
      <Box className={btnStyles.bottomNavContainer}>
            <BottomNavigation sx={{width:'100%',
             position:'fixed', 
             bottom:0,
             height:'70px',
             zIndex: '1000'
             }} showLabels>
              <BottomNavigationAction  id="navNextButton" label='Make another report' icon={<ContentPasteIcon />} onClick={()=> homePage()}/>
            </BottomNavigation>
      </Box>

    </>
  );
}

